<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        :title="title"
        color="primary"
      >
        <slot />
      </base-info-card>

      <template v-for="({ icon, text, title: t }, i) in business">
        <base-avatar-card
          :key="i"
          :icon="icon"
          :outlined="false"
          :title="!dense ? t : undefined"
          color="transparent"
          horizontal
          space="0"
        >
          <!-- Do not use v-html for user -->
          <!-- provided values -->
          <div v-html="text" />
        </base-avatar-card>

        <v-divider
          v-if="i + 1 !== business.length"
          :key="`divider-${i}`"
          class="my-2"
        />
      </template>
      <v-divider
        class="my-2"
      />
      <base-avatar-card
        icon="far fa-envelope"
        :outlined="false"
        :title="!dense ? t : undefined"
        color="transparent"
        horizontal
        space="0"
      >
        <!-- Do not use v-html for user -->
        <!-- provided values -->
        <base-btn
          :color="!theme.isDark ? 'accent' : 'white'"
          href="mailto:contact@aptamimetics.com?subject=Get%20In%20Touch"
          outlined
          target="_blank"
        >
          Send us a Mail
        </base-btn>
      </base-avatar-card>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessContact',

    inject: ['theme'],

    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
    },

    data: () => ({
      business: [
        {
          icon: 'far fa-map-marker-alt',
          title: 'Contact Address',
          text: 'Aptamimetics GmbH<br>Georges-Köhler-Allee 103<br>79110 Freiburg',
        },
        {
          icon: 'far fa-mobile-alt',
          title: 'Phone',
          text: '+49 151 685 692 82<br>',
        },
      ],
    }),
  }
</script>
