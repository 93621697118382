// Vuetify Documentation https://vuetifyjs.com
import '@fortawesome/fontawesome-pro/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import {
  VCol,
  VRow,
} from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VCol,
    VRow,
  },
})

export default new Vuetify({
  iconfont: ['mdi', 'fa'],
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#00A7E1', // '#F4511E', // '#F4511E', // '#42a5f6'
        secondary: '#061A40', // '#050b1f', // '#1c114a', // '#21135C', // '#311B92',
        accent: '#204165', // '#FFFCF5', // '#21135C', // '#050b1f', // '#204165', '#4A148C',  '#512DA8',
        background: '#FFFFFF', // '#FFFCF5', // Not automatically applied
      },
      dark: {
        primary: '#00A7E1', // '#F4511E', // '#F4511E', // '#42a5f6'
        secondary: '#FFFCF5', // '#050b1f', // '#1c114a', // '#21135C', // '#311B92',
        accent: '#204165', // '#FFFCF5', // '#21135C', // '#050b1f', // '#204165', '#4A148C',  '#512DA8',
        background: '#061A40', // Not automatically applied
      },
    },
  },
})
