<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  export default {
    name: 'AptamimeticsCom',
    metaInfo: {
      title: 'Aptamimetics.com',
      titleTemplate: '%s | Aptamimetics',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        // { name: 'title', property: 'og:title', content: 'Aptamimetics - Biopharmaceuticals based on nucleic acids' },
        // { name: 'author', content: 'Aptamimetics GmbH' },
        // { name: 'image', property: 'og:image', content: 'https://aptamimetics.com/meta/logo-meta.png' },
        // { name: 'description', property: 'og:description', content: 'Aptamimetics develops biopharmaceuticals based on nucleic acids to address unmet medical needs.' },
        // { name: 'url', property: 'og:url', content: 'https://aptamimetics.com/' },
      ],
    },
  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>
