<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        title="GET IN TOUCH"
        pace="6"
      >
        You want to learn more about our products? You are looking for funding opportunities? You want to become a partner? Contact us!
      </base-info-card>

      <base-business-contact dense />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',

    props: { dark: Boolean },

    data: () => ({
    }),
  }
</script>
