<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        title="Follow us"
        space="6"
      >
        Never miss an update. Join us on LinkedIn to get our latest news.
      </base-info-card>

      <base-section
        id="social"
        class="text-center"
        space="50"
      >
        <v-row>
          <v-col
            class="xs"
            cols="12"
            md="12"
          >
            <base-icon
              class="mb-8"
              color="primary"
            >
              fab fa-linkedin
            </base-icon>
            <br>
            <v-btn
              class="font-weight-bold"
              color="primary"
              href="https://www.linkedin.com/company/aptamimetics"
              light
              min-width="168"
              target="_blank"
              x-large
            >
              LinkedIn
              <v-icon right>
                far fa-external-link
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!-- <div class="py-2" />
        <a
          class="d-inline-block mb-8"
          href="https://aptamimetics.com"
          style="text-decoration: none;"
          target="_blank"
        >
          https://aptamimetics.com
        </a>

        <div class="py-2" /> -->
      </base-section>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseSocialMediaFollow',

    inject: ['theme'],

    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
    },

    data: () => ({
      business: [
        {
          icon: 'far fa-map-marker-alt',
          title: 'Address',
          text: 'Runzstr. 69<br>Freiburg i. Br.',
        },
        {
          icon: 'far fa-mobile-alt',
          title: 'Phone',
          text: '01 (800) 433 744<br>01 (800) 433 633',
        },
      ],
    }),
  }
</script>
